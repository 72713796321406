import fetchData from '@helpers/fetchData';
import { elementsInViewOnce } from '@helpers/elementsInView';
import type Mapboxgl from 'mapbox-gl';

const accessToken =
  'pk.eyJ1Ijoiam9yZGFuc3NvbGljaXRvcnMiLCJhIjoiY2x1czl6dzI3MGptbTJpdWpwd3NmMTkyeSJ9.5FAU_5K8YOpdnPjMNONYaQ';
const mapboxStyle = 'mapbox://styles/jordanssolicitors/clxbuwgds025r01pc13v219ss';
const markerColour = '#E8751E';
const defaultZoom = 6;

/**
 * Import mapbox-gl dependencies once when the map is in view with a 250px buffer
 */
const importDepenciesOnceWhenInView = elementsInViewOnce(
  () => Promise.all([import('mapbox-gl'), import('mapbox-gl/dist/mapbox-gl.css')]),
  250,
);

export default async function init() {
  const mapContainer = document.querySelector<HTMLDivElement>('.js-map');
  if (!mapContainer) return;

  // If the map block exists there should always be a mapContainer and location is a required field
  // so should always have a value. No error message needed.
  const location = getLocation(mapContainer);
  const zoom =
    'zoomlevel' in mapContainer.dataset ? Number(mapContainer.dataset.zoomlevel) : defaultZoom;
  const [{ default: mapboxgl }] = await importDepenciesOnceWhenInView([mapContainer]);
  await initializeMap(mapboxgl, mapContainer, location, zoom);
}

/**
 * Get the location from the map container
 */
const getLocation = (container: HTMLElement) => {
  const placeName = container.dataset.location;
  const lng = 'lng' in container.dataset ? Number(container.dataset.lng) : undefined;
  const lat = 'lat' in container.dataset ? Number(container.dataset.lat) : undefined;

  if (lng && lat) {
    return [lng, lat];
  }

  if (!placeName) {
    console.error('No location or coordinates provided', container);
    throw new Error('No location or coordinates provided');
  }
  return placeName;
};

/**
 * Initialize the map
 */
const initializeMap = async (
  mapboxgl: typeof Mapboxgl,
  container: HTMLDivElement,
  location: string | number[],
  zoom: number,
) => {
  try {
    const geoData = await fetchGeoData(location);
    if (!geoData || !geoData.features || !geoData.query) {
      throw new Error('Invalid geodata received');
    }

    const coords = typeof location === 'object' ? geoData.query : geoData.features[0].center;
    mapboxgl.accessToken = accessToken;
    const map = new mapboxgl.Map({
      container: container,
      style: mapboxStyle,
      center: coords,
      zoom: zoom,
      scrollZoom: false,
    });
    map.addControl(new mapboxgl.NavigationControl());

    new mapboxgl.Marker({ color: markerColour }).setLngLat(coords).addTo(map);
  } catch (error) {
    console.error(`Map initialization error`, error);
  }
};

/**
 * Fetch geodata from Mapbox
 */
const fetchGeoData = async (location: string | number[]) => {
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${location}.json?access_token=${accessToken}`;
  return fetchData(url).catch((error) => {
    console.error(`Error fetching geodata`, error);
    throw error; // Re-throw the error to be caught in initializeMap
  });
};
